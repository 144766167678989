import * as React from "react"
import Helmet from "react-helmet";

import Layout from "../components/Layout"
import Banner from "../components/Banner"
import PrivacyPolicy from "../components/PrivacyPolicy"

const Aboutpage = () => {
    return (
        <>
            <Helmet>
                <title>Polityka prywatności</title>
            </Helmet>
            <Layout>
                <Banner />
                <PrivacyPolicy />
            </Layout>
        </>

    );
};
export default Aboutpage;