import * as React from "react"

import {PageContent, Container,} from "./styled"

const AboutSection = () => {
    return (
        <>
            <PageContent>
                <Container>
                    <h1>Polityka prywatnoci</h1>

                    <p>
                        <b>1. </b>Administratorem danych jest firma Kancelaria Notarialna M.Dąbrowska notariusz J.Szeszkowska notariusz S.C. z siedzibą w Warszawie 02-009 przy Al. Niepodległości 245 lok. 103. Ochrona danych odbywa się zgodnie
                        z wymogami powszechnie obowiązujących przepisów prawa, a ich przechowywanie ma miejsce na zabezpieczonych serwerach.
                    </p>
                    <p>
                        <b>2. </b>Dla interpretacji terminów stosuje się słowniczek Regulaminu lub tak jak zostało to opisane w Polityce
                        Prywatności (jeżeli wynika to bezpośrednio z opisu).
                    </p>
                    <p>
                        <b>3. </b>Na potrzeby lepszego odbioru Polityki Prywatności termin „Użytkownik” zastąpiony został
                        określeniem „Ty”,  „Administrator” - „My”. Termin „RODO” oznacza Rozporządzenie Parlamentu Europejskiego
                        i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
                        danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
                    </p>
                    <p>
                        <b>4. </b>Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W tym celu używany
                        jest m.in. bezpieczny protokół szyfrowania komunikacji (SSL).
                    </p>
                    <p>
                        <b>5. </b>Dane osobowe podawane w formularzu na stronie https://notariuszeds.pl są traktowane jako poufne i nie
                        są widoczne dla osób nieuprawnionych.
                    </p>
                    <p>
                        <b>6. </b>Usługodawca jest administratorem danych swoich klientów. Oznacza to, że jeśli skontaktowałeś się z nami wypełniając formularz kontaktowy na stronie,
                        lub podałeś nam swoje dane w inny sposób kontaktując się z nami, to przetwarzamy Twoje dane jak: imię, nazwisko, adres e-mail, numer telefonu,
                        adres IP i inne przesłane przez Ciebie.
                    </p>
                    <p>
                        <b>7. </b>Dane osobowe przetwarzane są; zgodnie z przepisami dotyczącymi ochrony danych osobowych, zgodnie z wdrożoną Polityką
                        Prywatności, w zakresie i celu niezbędnym do nawiązania, ukształtowania treści Umowy, zmiany bądź jej rozwiązania oraz prawidłowej
                        realizacji Usług świadczonych drogą elektroniczną, w zakresie i celu niezbędnym do wypełnienia uzasadnionych interesów
                        (prawnie usprawiedliwionych celów), a przetwarzanie nie narusza praw i wolności osoby, której dane dotyczą: w zakresie i celu
                        zgodnym ze zgodą wyrażoną przez Ciebie jeśli wypełniłeś formularz kontaktowy.
                    </p>
                    <p>
                        <b>8. </b>Każda osoba, której dane dotyczą (jeżeli jesteśmy ich administratorem) ma prawo dostępu do danych, sprostowania, usunięcia
                        lub ograniczenia przetwarzania, prawo sprzeciwu, prawo wniesienia skargi do organu nadzorczego.
                    </p>
                    <p>
                        <b>9. </b>Kontakt z osobą nadzorującą przetwarzanie danych osobowych w organizacji Usługodawcy jest możliwy drogą elektroniczną
                        pod adresem e-mail: szeszkowska@notariusze.waw.pl.
                    </p>
                    <p>
                        <b>10. </b>Zastrzegamy sobie prawo do przetwarzania Twoich danych po rozwiązaniu Umowy lub cofnięciu zgody tylko w zakresie na potrzeby
                        dochodzenia ewentualnych roszczeń przed sądem lub jeżeli przepisy krajowe albo unijne bądź prawa międzynarodowego obligują nas do retencji danych.
                    </p>
                    <p>
                        <b>11. </b>Usługodawca ma prawo udostępniać dane osobowe Użytkownika oraz innych jego danych podmiotom upoważnionym na podstawie właściwych
                        przepisów prawa (np. organom ścigania).
                    </p>
                    <p>
                        <b>12. </b>Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody bądź wniesienia prawnie dopuszczalnego sprzeciwu na
                        przetwarzanie danych osobowych.
                    </p>
                    <p>
                        <b>13. </b>Usługodawca nie udostępniania danych osobowych innym podmiotom aniżeli upoważnionym na podstawie właściwych przepisów prawa.
                    </p>
                    <p>
                        <b>14. </b>Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas albo przetwarzający, z którymi ściśle współpracujemy.
                    </p>

                    {/* <h1>Pliki cookies</h1>
                    <p>
                        <b>1. </b>Witryna https://notariuszeds.pl używa cookies. Są to niewielkie pliki tekstowe wysyłane przez serwer
                        www i przechowywane przez oprogramowanie komputera przeglądarki. Kiedy przeglądarka ponownie połączy się
                        ze stroną, witryna rozpoznaje rodzaj urządzenia, z którego łączy się użytkownik. Parametry pozwalają na
                        odczytanie informacji w nich zawartych jedynie serwerowi, który je utworzył. Cookies ułatwiają więc
                        korzystanie z wcześniej odwiedzonych witryn.
                    </p>
                    <p>
                        <b>2. </b>Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób użytkownicy korzystają z naszych
                        witryn, aby usprawniać funkcjonowanie serwisu zapewniając bardziej efektywną i bezproblemową nawigację.
                        Zapamiętując jego preferencje, umożliwia odpowiednie dopasowanie
                        skierowanych do niego reklam. Stosujemy pliki cookies, aby zagwarantować najwyższy standard wygody naszego
                        serwisu, a zebrane dane są wykorzystywane jedynie wewnątrz firmy w celu optymalizacji działań.
                    </p>
                    <p>
                        <b>3. </b>Na naszej witrynie wykorzystujemy następujące pliki cookies
                        <ul>
                        <li>„wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych serwisu</li>
                        <li>„funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez użytkownika ustawień i personalizację interfejsu
                            użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.</li>
                        </ul>
                    </p>
                    <p>
                        <b>4. </b>Użytkownik w każdej chwili ma możliwość wyłączenia lub przywrócenia opcji gromadzenia cookies
                        poprzez zmianę ustawień w przeglądarce internetowej. Dodatkowe dane osobowe, jak adres e-mail, zbierane są jedynie w
                        miejscach, w których użytkownik wypełniając formularz wyraźnie wyraził na to zgodę. Powyższe dane zachowujemy i
                        wykorzystujemy tylko do potrzeb niezbędnych do wykonania danej funkcji.
                    </p> */}

                </Container>
            </PageContent>

        </>

    );
};
export default AboutSection;